@import "../../styles/mixins/breakpoints.scss";
@import "../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  pointer-events: all;

  &:hover {
    .image {
      transform: translateX(5px);
      transition: transform 0.4s ease;
    }
  }
}

a:focus {
  color: $cyan;
}

.caption {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 10px;
}

.fontSmall {
  font-size: 12px;
}

.fontMedium {
  font-size: 14px;
}

.fontLarge {
  font-size: 18px;
}

.fontSmall,
.fontMedium,
.fontLarge {
  @include minWidth(desktop) {
    font-size: 18px;
  }
}

.imageSmall {
  width: 12px;
  height: 12px;
}

.imageMedium {
  width: 14px;
  height: 14px;
}

.imageLarge {
  width: 18px;
  height: 18px;
}

.imageSmall,
.imageMedium,
.imageLarge {
  @include minWidth(desktop) {
    width: 18px;
    height: 18px;
  }
}
