@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.container {
  width: 90%;
  height: 100%;
  min-height: 250px;
  background: #fff;
  box-shadow: 4px 5px 5px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;

  padding: 20px;

  margin-bottom: 40px;

  @include minWidth(desktop) {
    margin-bottom: 0;
    width: 520px;
  }
}

.title {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 20px;

  @include minWidth(desktop) {
    font-size: 28px;
  }
}

.description {
  min-height: 50px;
  overflow: hidden;

  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  margin-bottom: 15px;
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 90%;
  height: 200px;

  margin: 0 auto;

  margin-bottom: 35px;

  @include minWidth(desktop) {
    width: 93%;
    height: 320px;

    // max-width: 1096px;
    // max-height: 685px;
  }
}

.downloadBtn {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 50px;

  background-color: $cyan;

  border: 2px solid $cyan;
  color: $white;

  text-transform: uppercase;

  margin: 0 auto;

  margin-top: 10px;

  padding: 21px 55px 21px 55px;
  min-width: 300px;

  align-self: flex-end;

  &:hover,
  &--active {
    background-color: $cyanHover;
    border: 2px solid $white;
    color: $white;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
  //   padding-bottom: 30px;
}

.modal {
  position: "absolute" as "absolute";
  top: 50%;
  left: 50%;
  transform: "translate(-50%, -50%)";
  width: 50%;
  height: 50%; //60
  // bgcolor: "background.paper";
  border: "2px solid #000";
  // boxShadow: 24;
  p: 4;
}
