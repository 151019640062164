@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.eventsContainer {
  display: flex;

  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include minWidth(desktop) {
    width: 1104px;
    margin-top: 50px;
  }
}
