$lightGreyBlue0: #f7f9fa;
$lightGreyBlue1: #e3e9ee;
$lightGreyBlue2: #c8cacc;
$greyBlue0: #aebcc7;
$greyBlue1: #9eafba;
$greyBlue2: #a1adb7;
$greyBlue3: #838c95;
$darkGreyBlue0: #8394a2;
$darkGreyBlue2: #687c8d;
$darkGreyBlue3: #44525c;
$darkGreyBlue4: #2a333a;
$darkGreyBlue5: #1c1d1f;
$darkGrey1: #2d2d2e;
$cyan: #7dedfa;
$skyBlue: #88c0fc;
$blue1: #0258b6;
$blue2: #3787dd;

$blue3: #3978be;
$blue4: #0a3d74;
$blue5: #4470a1;
$grey1: #f9f9f9;

$grey2: #dbdbdb;
// $grey3: #666666;
$grey4: #999999;
// $green: #388e3c;
// $orange: #f57c00;
// $yellow: #fec10b;
// $red: #c31616;

//------ Spero
$divider: #696ca5;

$purple0: #52559e;
$purple1: #333787;

$cyan: #33bbc5;
$cyanHover: #29a9b2;

$darkBlue: #333787;

$white: #ffffff;
$lightGrey0: #eeeeee;
$lightGrey1: #dddddd;
$lightGrey2: #cccccc;
$lightGrey3: #b0b1c1;
$lightGrey4: #f1f1f1;
$darkGrey1: #dedede;
$darkGrey0: #999999;
$black0: #111111;
$black1: #000000;
$red: #c53333;
$lightGreen: #6abe6d;
