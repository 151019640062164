@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.container {
  width: 100%;
  height: 100%; //300px;

  padding: 17px 22px 14px 22px;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  margin-bottom: 30px;

  display: flex;

  flex-direction: column;

  border-radius: 25px;

  @include minWidth(desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 920px;
    height: 120px;
  }
}

.avatar {
  width: 100px;
  height: 100px;
  margin-right: 13px;
}

.leftSection {
  display: flex;
  margin-bottom: 30px;

  @include minWidth(desktop) {
    margin-bottom: 0;
  }
}

.leftSectionDataWrapper {
  display: flex;
  flex-direction: column;
}

.radioContainer {
  display: flex;
  flex-direction: row;
}

.name {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 6px;
}

.radioContainer {
  display: flex;
  flex-direction: row;
}

.volunteerDataContainer {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: column;

  @include minWidth(desktop) {
    flex-direction: row;
  }
}

.volunteerDataText {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-right: 6px;
}

.emailVolunteer {
  @include minWidth(desktop) {
    margin-right: 20px;
  }
}

.seeDetailsButton {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50px;
  cursor: pointer;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: 2px solid $cyan;
  color: black;
  border-color: $cyan;
  text-transform: uppercase;

  background-color: transparent;

  min-width: 70%;
  min-height: 40px;

  &:hover,
  &--active {
    background-color: $cyanHover;
    color: $white;
  }

  @include minWidth(desktop) {
    font-size: 12px;

    min-width: 160px;
    min-height: 40px;
  }
}

.seeDetailsButtonDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  border-radius: 50px;
  // cursor: pointer;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: 2px solid #93dde7;
  color: #ccc;

  text-transform: uppercase;

  background-color: transparent;

  min-width: 70%;
  min-height: 40px;

  // &:hover,
  // &--active {
  //   background-color: $cyanHover;
  //   color: $white;
  // }

  @include minWidth(desktop) {
    font-size: 12px;

    min-width: 160px;
    min-height: 40px;
  }
}

.rightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 20px;
  }

  @include minWidth(desktop) {
    flex-direction: row;

    a:first-child {
      margin-right: 11px;
    }

    & > :first-child {
      margin-bottom: 0;
    }
  }
}
