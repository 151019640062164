@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 90%;
  height: 200px;

  margin: 0 auto;

  @include minWidth(desktop) {
    width: 1096px;
    height: 685px;

    max-width: 1096px;
    max-height: 685px;
  }
}

.container {
  z-index: -1;
}

img {
  object-fit: cover;
}
