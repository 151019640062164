@import "../../styles/mixins/eventBox.scss";

.socialIcons {
  margin: 38px auto 40px auto;

  display: flex;
  justify-content: center;

  @include minWidth(desktop) {
    margin: 25.27px 0 0 auto;

    justify-content: right;
    align-items: flex-end;
  }
}

.socialIconFooter {
  &:not(:last-child) {
    margin-right: 20px;
  }
}

.socialIconProfile {
  &:not(:last-child) {
    margin-right: 46px;
  }
}
