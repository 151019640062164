@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints.scss";

.eventSliderContainer {
  padding: 0 0 41px 30px;

  @include minWidth(desktop) {
    padding: 0 0 60px 416px;
  }
}

.box {
  height: 500px;

  @include minWidth(desktop) {
    height: 760px;
  }
}
