@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints.scss";

.field {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.content {
  padding: 24px 0;

  @include minWidth(desktop) {
    display: flex;
  }
}

.photoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.photoCircle {
  border-radius: 50%;
  background: $grey1;
  color: rgba(42, 51, 58, 0.2);
  border: 1px solid rgba(42, 51, 58, 0.2);
  width: 100px;
  height: 100px;
  margin-bottom: 25px;

  @include minWidth(desktop) {
    width: 135px;
    height: 135px;
  }
}

.box {
  background: $grey1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 242px;
  margin-bottom: 30px;
}

.assetBox {
  margin-bottom: 30px;
}

.socialFieldContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
}

.socialIcon {
  width: 28px;
  height: 28px;
  margin: 0 15px;
  color: $grey4;
}

.userInputContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: row;

  @include minWidth(desktop) {
    justify-content: space-between;
  }
}

.halfInput {
  @include minWidth(desktop) {
    width: 49%;
  }
}

.sectionNameFirst {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $black0;

  display: block;

  margin: 30px 0 10px 0;

  @include minWidth(desktop) {
    margin: 0 0 10px 0;
  }
}

.sectionName {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $black0;

  display: block;

  margin: 20px 0 10px 0;
}

// .privacySwitch {
//   .MuiTypography-root {
//     font-size: 18px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
//     color: $black0;
//   }
// }

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;

  @include minWidth(desktop) {
    font-size: 36px;
    margin-bottom: 40px;
  }
}
