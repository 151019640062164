@import "../../styles/colors";
@import "../../styles/mixins/breakpoints.scss";

.container {
  padding: 22px 20px 22px 20px;
  border: 2px solid $lightGreen;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 5px 5px 4px 0 rgba(0, 0, 0, 0.1);
}

.innerContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  img {
    margin-right: 3px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topText {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #000;

  margin-bottom: 10px;

  @include minWidth(desktop) {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.bottomText {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $lightGreen;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}
