@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.box {
  @include eventBox;
  width: 80%;
  height: 500px;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    height: 750px;
    max-width: 520px;

    flex: 0 0 50%;

    &:nth-child(odd) {
      margin-right: 64px;
    }

    margin-bottom: 60px;
  }
}

.eventsContainer {
  display: flex;

  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include minWidth(desktop) {
    width: 1104px;
  }
}
