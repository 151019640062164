@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.container {
  width: 100%;
  height: 100%;

  background-color: $lightGrey4;

  border-radius: 25px;

  @include minWidth(desktop) {
    width: 920px;
    height: 390px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $cyan;

  width: 480px;

  margin-top: 78px;
  margin-bottom: 13px;

  @include minWidth(desktop) {
    font-size: 36px;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.text {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  padding-left: 20px;
  padding-right: 20px;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    font-size: 18px;
    width: 650px;
    margin-bottom: 0;
  }
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
