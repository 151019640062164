@import "../../../styles/colors";
@import "../../../styles/mixins/eventBox.scss";
@import "../../../styles/mixins/reset";

.container {
  width: 100%;
  padding: 17px 10px 14px 10px;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  margin-bottom: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 25px;

  @include minWidth(desktop) {
    padding: 17px 22px 14px 22px;

    width: 920px;
    height: 125px;
    justify-content: space-between;
    flex-direction: row;
  }
}

.avatar {
  width: 100px;
  height: 100px;
  margin-right: 13px;
}

.leftSection {
  display: flex;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    margin-bottom: 0;
  }
}

.name {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 15px;
}

.locationIcon {
  width: 21px;
  height: 23px;
}

.locationDatesContainer {
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @include minWidth(desktop) {
    flex-direction: row;
  }
}

.headerDivider {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 40px;
  margin-right: 15px;
  margin-left: 15px;
}

.locationContainer {
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  @include minWidth(desktop) {
    margin-right: 25px;
    margin-bottom: 0;
  }
}

.datesContainer {
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.seeDetailsButton {
  border-radius: 50px;
  cursor: pointer;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: 2px solid $cyan;
  color: black;
  border-color: $cyan;
  text-transform: uppercase;

  background-color: transparent;

  margin: 0 5px;

  padding: 13px 30px 13px 30px;
  // margin-bottom: 41px;

  &:hover,
  &--active {
    background-color: $cyanHover;
    color: $white;
  }

  @include minWidth(desktop) {
    font-size: 12px;

    padding: 13px 15px 13px 15px;

    max-width: 250px;
    max-height: 60px;

    margin-bottom: 0;
  }
}

.certificateBtn {
  margin-top: 10px;
  @include minWidth(desktop) {
    margin-top: 0;
  }
}

.rightSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.statusApproved {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $lightGreen;

  text-transform: uppercase;

  margin-left: 10px;

  @include minWidth(desktop) {
    font-size: 16px;
  }
}

.statusPending {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $lightGrey2;

  text-transform: uppercase;

  margin-left: 10px;

  @include minWidth(desktop) {
    font-size: 16px;
  }
}

.statusRejected {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $red;
  text-transform: uppercase;

  margin-left: 10px;

  @include minWidth(desktop) {
    font-size: 16px;
  }
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 20px;
}
