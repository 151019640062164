.container {
  padding-top: 80px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 55px;
}

.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 35px;
}

.textBold {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 35px;
}
