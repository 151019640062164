@import "./breakpoints.scss"; //"styles/mixins/breakpoints";

$borderRadius: 20px;

@mixin eventBox {
  width: 100%;
  // height: 320px;
  background: #fff;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;

  box-shadow: inset 0px 0px 15px -1px rgba(0, 0, 0, 0.1);

  @include minWidth(desktop) {
    // width: 444px;
    box-shadow: inset 0px 0px 26px -1px rgba(0, 0, 0, 0.1);
  }

  a {
    color: currentColor;
  }
}
