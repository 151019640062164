@import "../../styles/colors";
@import "../../styles/mixins/reset";
@import "../../styles/mixins/breakpoints";

.halfField {
  width: 49%;
  margin-bottom: 15px;
}

.fullField {
  width: 100%;
  margin-bottom: 15px;
}

.namesContainer {
  display: flex;
  justify-content: space-between;
}

.textArea {
  width: 100%;
  border-radius: 25px;
  padding: 15px;
  margin-bottom: 45px;
  border: 1px solid #ccc;

  resize: none; // Disable textarea resizing
  &::both {
    resize: none; // Disable resizing handle for Webkit browsers
  }
  &::-webkit-resizer {
    display: none; // Hide resizing handle for Webkit browsers
  }
}

.submitBtn {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 50px;

  background-color: $purple0;

  border: 2px solid $purple0;
  color: $white;

  text-transform: uppercase;

  min-width: 300px;
  min-height: 60px;

  &:hover,
  &--active {
    background-color: $purple1;
    border: 2px solid $white;
    color: $white;
  }

  @include minWidth(desktop) {
    margin-top: 20px;
    font-size: 18px;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
