@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.container {
  padding-top: 90px;
}

.contentContainer {
  @include minWidth(desktop) {
    padding: 64px 40px 64px 40px;

    border-radius: 25px;

    margin-top: -250px;

    // padding-top: 50px;
  }
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  margin-bottom: 55px;
}

.text {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 90%;
  height: 200px;

  margin: 0 auto;

  @include minWidth(desktop) {
    width: 1096px;
    height: 685px;

    max-width: 1096px;
    max-height: 685px;
  }
}
