@import "../../styles/colors";

.field {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  background-color: #fff;
  border-radius: 3px;
}

.content {
  display: flex;
  padding: 30px 0;
}

.title {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 30px 0 30px 0;

  &--border {
    border-bottom: 1px solid $grey2;
  }
}

.addBtn {
  display: flex;
  margin: 15px auto;
  color: $grey4;
  text-transform: capitalize;
}
