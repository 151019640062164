@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.date {
  margin: 0 28px 0 31px;
}

.dateDay {
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dedede;

  @include minWidth(desktop) {
    font-size: 48px;
  }
}

.dateMonth {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dedede;

  @include minWidth(desktop) {
    font-size: 20px;
  }
}

.divider {
  border-left: 2px solid #dedede;
  height: 76px;
  margin-right: 28px;

  @include minWidth(desktop) {
    height: 121px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: row;
  margin: 20px 0 20px 0;

  @include minWidth(desktop) {
    margin: 40px 0 25px 0;
  }
}

.contentContainer {
  @include minWidth(desktop) {
    padding: 2px 40px 40px 40px;

    margin-top: -220px;

    border-radius: 25px;
  }
}

.title {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  width: 50%;
  @include minWidth(desktop) {
    font-size: 32px;
    width: 100%;
  }
}

.blogText {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.shareContainer {
  margin-left: auto;
}

.shareText {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $darkGrey1;
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 90%;
  height: 200px;

  margin: 0 auto;

  @include minWidth(desktop) {
    width: 1096px;
    height: 685px;

    max-width: 1096px;
    max-height: 685px;
  }
}
