@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.contentContainer {
  padding: 64px 43px 52px 43px !important;

  margin-top: -250px;

  border-radius: 25px;
}

.title {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 50px;

  @include minWidth(desktop) {
    font-size: 36px;
  }
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 90%;
  height: 200px;

  margin: 0 auto;

  @include minWidth(desktop) {
    width: 1096px;
    height: 685px;

    max-width: 1096px;
    max-height: 685px;
  }
}

.progressBar {
  height: 12px;
  border-radius: 25px;
  width: 130%;

  @include minWidth(desktop) {
    height: 20px;

    width: 140%;
  }
}

.headerDivider {
  border-right: 1px solid #ccc;
  height: 40px;
  margin-right: 20px;
  margin-left: 20px;
}

.volunteersText {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-align: right;

  @include minWidth(desktop) {
    font-size: 18px;
  }
}

.progresContainer {
  margin-bottom: 42px;
}

.locationDatesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    font-size: 18px;

    margin-bottom: 20px;

    justify-content: space-between;
    align-items: initial;
    flex-direction: row;
  }
}

.locationContainer {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
  }

  margin-bottom: 15px;

  @include minWidth(desktop) {
    margin-bottom: 15px;
  }
}

.datesContainer {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
  }
}

.volunteersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    flex-direction: row;
  }
}

.volunteerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: 15px;

  background-color: $lightGrey4;

  min-width: 100%;
  min-height: 120px;

  &:first-child {
    margin-bottom: 20px;
  }

  @include minWidth(desktop) {
    min-width: 48%;
    min-height: 109px;

    &:first-child {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.numberOfVolunteers {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $cyan;
}

.numberOfWorkingHours {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $purple1;
}

.costsContainer {
  //   display: flex;
  //   align-items: center;
  //   justify-content: row;

  //   margin: 0 auto;
  text-align: center;
  padding: 20px;
  border: 1px solid $purple0;
  border-radius: 25px;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $purple0;

  margin-bottom: 40px;
}

.sectionName {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 40px;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}

.sectionNameCenter {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 10px;

  @include minWidth(desktop) {
    font-size: 24px;
    margin-bottom: 0;
  }
}

.description {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 40px;

  @include minWidth(desktop) {
    font-size: 18px;
  }
}

.customBulletList {
  list-style: none; /* Remove default bullets */
  padding: 0;

  margin-bottom: 40px;

  margin-left: 15px;

  font-size: 15px;

  @include minWidth(desktop) {
    font-size: 18px;
  }
}

.bulletIcon {
  margin-right: 20px;
}

.greenTips {
  margin-bottom: 40px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitBtn {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 50px;

  background-color: $cyan;

  border: 2px solid $cyan;
  color: $white;

  text-transform: uppercase;

  margin: 0 auto;

  margin-top: 10px;

  padding: 21px 55px 21px 55px;
  min-width: 300px;

  &:hover,
  &--active {
    background-color: $cyanHover;
    border: 2px solid $white;
    color: $white;
  }

  @include minWidth(desktop) {
    margin-top: 20px;
    font-size: 18px;
    padding: 19px 10px 19px 10px;
    min-width: 350px;

    margin-bottom: 20px;
  }
}

//----
// :root {
//   --bullet-size: 12px; /* Define a CSS variable for bullet size */
//   --bullet-color: #ccc; /* Define a CSS variable for bullet color */
// }

// .customBulletList li::marker {
//   font-size: 25rem;
// }

// .customBulletList li::before {
//   content: "";
//   color: var(--bullet-color); /* Use the CSS variable for bullet color */
//   font-size: var(--bullet-size); /* Use the CSS variable for bullet size */
//   line-height: var(
//     --bullet-size
//   ); /* Set line-height to match the bullet size */
//   display: inline-block;
//   width: var(--bullet-size);
//   height: var(--bullet-size);
//   margin-right: 8px;
//   //   font-size: 40px;
// }
