@import "../../../styles/colors";
@import "../../../styles/mixins/eventBox.scss";
@import "../../../styles/mixins/reset";

.box {
  @include eventBox;
  width: 279px;
  height: 100%;

  @include minWidth(desktop) {
    height: 700px;
    width: auto;
  }
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 87%;
  height: 200px;

  margin: 0 auto;

  margin-bottom: 35px;

  @include minWidth(desktop) {
    width: 88%;
    height: 320px;

    // max-width: 1096px;
    // max-height: 685px;
  }
}

.title {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 18px 80px 2px 17px;

  width: 90%;

  @include minWidth(desktop) {
    margin: 29px 110px 0 26px;
    height: 95px;
    font-size: 32px;
  }
}

.subtitle {
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  color: $purple0;

  margin: 0 80px 4px 17px;

  @include minWidth(desktop) {
    margin: 0 110px 0 26px;
    font-size: 24px;
  }
}

.dates {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0 80px 20px 17px;

  @include minWidth(desktop) {
    margin: 0 110px 25px 26px;
    font-size: 18px;
  }
}

.sliderImage {
  max-width: 400px;
  max-height: 250px;

  max-width: 100%;
  height: auto;

  // max-height: 50%;

  // margin: 0 17px 20px 17px;

  width: 90%;
  margin: 0 auto;
  border-radius: 25px;
  margin-bottom: 20px;

  @include minWidth(desktop) {
    // margin: 0 22px 37px 22px;

    margin-bottom: 37px;
  }
}

.volunteersCount {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 7px 25px;

  @include minWidth(desktop) {
    margin: 0 52px 10px 52px;
    font-size: 18px;
  }
}

.progressBar {
  height: 12px;
  width: 115%;
  border-radius: 25px;

  margin: 0 25px 20px 25px;

  // :global(.MuiBox-root) {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  // margin: 0 15px 28px 13px;
  // margin: 0 auto;

  @include minWidth(desktop) {
    margin: 0 52px 43px 52px;

    height: 20px;

    width: 110%;
  }
}

.MuiLinearProgress-root::before {
  background-color: #fff;
}

.seeDetailsButton {
  border-radius: 50px;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border: 2px solid $cyan;
  color: black;
  border-color: $cyan;
  text-transform: uppercase;

  background-color: transparent;

  margin: 0 auto;

  padding: 13px 50px 13px 50px;
  margin-bottom: 41px;

  &:hover,
  &--active {
    background-color: $cyanHover;
    color: $white;
  }

  @include minWidth(desktop) {
    font-size: 18px;

    padding: 12px 50px 12px 50px;

    // max-width: 190px;
    max-height: 60px;

    margin-bottom: 41px;
  }
}

.MuiLinearProgress-root::before {
  background-color: #fff;
}

.box {
  @include eventBox;
  width: 80%;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    height: 750px;
    max-width: 520px;

    flex: 0 0 50%;

    &:nth-child(odd) {
      margin-right: 64px;
    }

    margin-bottom: 60px;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0 auto;

  margin-bottom: 20px;

  @include minWidth(desktop) {
    width: 472px;
    flex-direction: row;

    margin-bottom: 41px;
  }
}

.buttonAction {
  border-radius: 50px;
  cursor: pointer;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-align: center;

  text-transform: uppercase;

  margin: 0 auto;

  min-height: 40px;
  min-width: 200px;

  text-align: center;

  @include minWidth(desktop) {
    font-size: 14px;

    min-width: 190px;
    min-height: 40px;
  }
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid $purple0;
  color: black;
  border-color: $purple0;
  text-transform: uppercase;

  margin-bottom: 20px;

  &:hover,
  &--active {
    background-color: $cyan;
    color: $white;
  }

  @include minWidth(desktop) {
    margin-bottom: 0;
  }
}

.volunteersButton {
  color: $white !important;
  background-color: $cyan;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &--active {
    background-color: $cyanHover;
    color: $white;
  }
}

.buttonIcon {
  margin-right: 8px;
}

.eventStatus {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 28px;
}

.statusApproved {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $lightGreen;

  text-transform: uppercase;

  margin-left: 10px;

  @include minWidth(desktop) {
    font-size: 16px;
  }
}

.statusDraft {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $lightGrey2;

  text-transform: uppercase;

  margin-left: 10px;

  @include minWidth(desktop) {
    font-size: 16px;
  }
}

.statusRejected {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $red;
  text-transform: uppercase;

  margin-left: 10px;

  @include minWidth(desktop) {
    font-size: 16px;
  }
}
