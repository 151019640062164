@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

.objective {
  display: flex;
  align-items: center;
  color: $grey4;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  > :first-child {
    margin: 0 10px;
  }
}

.photoContainer {
  background-color: $lightGrey4;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  margin: 0 auto;

  border-radius: 25px;

  width: 90%;
  height: 200px;

  @include minWidth(desktop) {
    width: 1096px;
    height: 685px;
  }
}

.formContainer {
  border-radius: 25px !important;

  @include minWidth(desktop) {
    margin-top: -250px !important;
    z-index: 1000;
  }
}

.field {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.userInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include minWidth(desktop) {
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
  }
}

:global(.MuiFormHelperText-root) {
  width: 100%;
  text-align: center;
  margin-left: -1px;
}

.datePicker {
  width: 49%;
  margin-bottom: 30px;
  // padding-right: px;

  :global(.MuiInputBase-root) {
    border-radius: 25px;
  }

  :global(.MuiButtonBase-root) {
    margin-right: 1px;
  }
}

.datesContainer {
  display: flex;
  justify-content: space-between;
}

.countryCostsContainer {
  display: flex;
  flex-direction: column;

  width: 100%;

  @include minWidth(desktop) {
    flex-direction: row;
  }
}

.thirdInput {
  width: 100%;

  &:last-child {
    margin-bottom: 20px;
  }

  @include minWidth(desktop) {
    width: 33%;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sectionNameFirst {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $black0;

  display: block;

  margin: 30px 0 10px 0;

  @include minWidth(desktop) {
    margin: 0 0 10px 0;
  }
}

.volunteersContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    flex-direction: row;
  }
}

.volunteerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-radius: 15px;

  background-color: $lightGrey4;

  min-width: 100%;
  min-height: 120px;

  &:first-child {
    margin-bottom: 20px;
  }

  @include minWidth(desktop) {
    min-width: 430px;
    min-height: 109px;

    &:first-child {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.volunteerField {
  width: 20%;
  margin-bottom: 12px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  :global(.MuiFormHelperText-root) {
    width: 200px;
    text-align: center;
  }
}

//Hide arrows of number input fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
