@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.contentContainer {
  @include minWidth(desktop) {
    padding: 64px 40px 64px 40px;

    border-radius: 25px;
  }
}

.userName {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 45px;
}

.locationContainer {
  display: flex;
  align-items: center;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.headerDivider {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 40px;
  margin-right: 20px;
  margin-left: 20px;
}

.locationSocialContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include minWidth(desktop) {
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 56px;
  }
}

.description {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 55px;
}

.organizedSportEvents {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 25px;

  @include minWidth(desktop) {
    margin-bottom: 25px;
    line-height: normal;
  }
}

.totalLabourHours {
  color: $purple1;
  font-size: 20px;
  font-style: italic;
  line-height: 1.7;
  margin-bottom: 25px;

  span {
    border: 2px solid $purple1;
    padding: 3px 8px 3px 3px;
    margin: 0 0 0 5px;
    font-size: 26px;
    font-weight: 700;
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
  }

  @include minWidth(desktop) {
    margin-bottom: 50px;
    line-height: normal;
  }
}
