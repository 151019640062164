@import "../../styles/colors";
@import "../../styles/mixins/breakpoints.scss";
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap);

.container {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 1061px;
  height: 750px;
  background-image: url(./spero_certificate_template_hq_optimized.jpg);
  background-size: contain;
  background-repeat: no-repeat;
}

.textContainer {
  position: relative;
  top: 315px;
  left: 246px;
  width: 600px;
  height: 353px;
}

.name {
  position: absolute;
  top: 5px;
  width: 600px;
  color: $purple1;
  font-family: "Playfair Display";
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 26px;
}

.eventName {
  position: absolute;
  top: 180px;
  width: 600px;
  color: $purple1;
  font-family: "Playfair Display";
  font-style: italic;
  text-align: center;
  font-size: 22px;
}

.manager {
  position: absolute;
  bottom: 0px;
  width: 240px;
  color: $purple1;
  text-align: center;
  font-weight: bold;
  font-family: "Playfair Display";
  font-style: italic;
  text-align: left;
  font-size: 18px;
}

.logo {
  position: absolute;
  bottom: -30px;
  left: -30px;
  right: 0;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 1px solid #ccc;
}

.issueDate {
  position: absolute;
  bottom: 0px;
  right: 0;
  text-align: right;
  width: 180px;
  color: $purple1;
  font-weight: bold;
  font-family: "Playfair Display";
  font-style: italic;
  text-align: left;
  font-size: 18px;
}
