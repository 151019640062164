@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints.scss";

.leftArrow,
.rightArrow {
  cursor: pointer;
  pointer-events: all;
}

.leftArrow {
  margin-right: 20px;
}

.customNext {
  &:after {
    transform: rotate(180deg);
    margin-left: -10px;
  }
}

.navigationWrapper {
  display: none;
  margin-top: 76px;

  @include minWidth(desktop) {
    display: flex;
    top: 85px;
    // position: absolute;
    // left: -25px;
    // justify-content: space-between;
    // width: calc(100% + 50px);
    z-index: 99;
    pointer-events: none;
  }
}

.customNext,
.customPrev {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $cyan;
  cursor: pointer;
  pointer-events: all;

  &:after {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: -13px;
    // background-image: url("./arrow.svg");
    background-position: center;
  }
  &:hover {
    background-color: $cyan;
  }
}
