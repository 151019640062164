@import "../../styles/colors";
@import "../../styles/mixins/breakpoints";

.container {
  background: #fff;
  padding: 20px;

  @include minWidth(desktop) {
    padding: 40px 60px;
    min-width: 0;
    flex: 1 1 0;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    width: 1000px;
    margin: 0 auto;
  }
}
