@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

$background: url("./volunteers-photo.png");

.container {
  background: $background center no-repeat, $white;
  box-shadow: inset 0 0 0 635px rgba(0, 0, 0, 0.8);

  height: 413px;

  @include minWidth(desktop) {
    height: 635px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 114px 0 17px 0;

  @include minWidth(desktop) {
    padding: 157px 0 17px 0;
  }
}

.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $purple0;

  margin-bottom: 5px;

  text-transform: uppercase;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}

.subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $white;

  text-align: center;

  width: 95%;

  @include minWidth(desktop) {
    max-width: 710px;
    font-size: 49px;
  }
}

.createSportEvent {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 50px;

  background-color: $purple0;

  border: 2px solid $purple0;
  color: $white;

  text-transform: uppercase;

  margin: 0 auto;

  margin-top: 41px;

  min-width: 250px;
  min-height: 60px;

  &:hover,
  &--active {
    background-color: $purple1;
    border: 2px solid $white;
    color: $white;
  }

  @include minWidth(desktop) {
    margin-top: 79px;
    font-size: 18px;

    min-width: 320px;
    min-height: 80px;

    max-width: 320px;
  }
}
