@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.educationalResourcesContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  padding-top: 90px;
}

.educationalResourcesFilterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 20px;
  margin-bottom: 20px;

  > :first-child {
    margin-bottom: 16px;
  }

  @include minWidth(desktop) {
    margin-top: 20px;
    margin-bottom: 40px;
    flex-direction: row;
  }
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $cyan;

  margin-bottom: 17px;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}

.subtitle {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 80%;

  text-align: center;

  color: $purple1;

  margin-bottom: 70px;

  @include minWidth(desktop) {
    font-size: 49px;
    width: 800px;
  }
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
  padding-bottom: 30px;

  font-size: 40px;

  :global(.MuiPaginationItem-root) {
    font-size: 1.2rem; // Change the font size of the buttons and numbers
    width: 2.5rem; // Change the width of the buttons and numbers
    height: 2.5rem; // Change the height of the buttons and numbers
  }
}

.resourcesContainer {
  display: flex;

  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include minWidth(desktop) {
    width: 1104px;

    & > :nth-child(odd) {
      margin-right: 50px;
      margin-bottom: 54px;
    }
    & > :nth-child(even) {
      margin-bottom: 54px;
    }
  }
}

.field {
  width: 80%;

  @include minWidth(desktop) {
    width: 15%;
  }
}
