@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 0 auto;

  margin-bottom: 28px;
}

.userPictureNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.title {
  color: $cyan;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 42px 0 15px 0;

  @include minWidth(desktop) {
    margin: 89px 0 24px 0;
    font-size: 24px;
  }
}

.lecturerAvatar {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  width: 70px;
  height: 70px;
  //   border: 5px solid rgba(255, 255, 255, 0.7);
  margin: 0 auto;
  background-color: $darkGrey1;
  color: $darkGrey0;

  margin-right: 10px;

  @include minWidth(desktop) {
    margin: 0;
    margin-right: 15px;

    width: 100px;
    height: 100px;
  }
}

.userName {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $purple0;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}
