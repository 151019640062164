.wrapper {
    width: 100% !important;
    display: block !important;
}

.toolbar {
    background: #fff;
    border: 1px solid rgba(42, 51, 58, 0.2) !important;
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.editor {
    height: 300px !important;
    border: 1px solid rgba(42, 51, 58, 0.2) !important;
    padding: 5px !important;
    border-radius: 4px !important;
    background: #fff;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.error {
    border-color: #c31616 !important;
}
