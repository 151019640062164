@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints.scss";

$background: url("../../assets/bg-with-map-right-left-figures.png");

.container {
  background: $background #fff no-repeat center top;
  min-height: 1800px;

  @include minWidth(desktop) {
    min-height: 2094px;
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 870px;

  margin: 0 auto;

  padding-top: 42px;

  @include minWidth(desktop) {
    padding-top: 90px;
  }
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $cyan;

  text-transform: uppercase;

  margin-bottom: 17px;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}

.subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $darkBlue;

  width: 75%;

  text-align: center;

  @include minWidth(desktop) {
    font-size: 49px;
    width: 100%;
  }
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 39px;
  margin-bottom: 45px;

  > :first-child {
    margin-bottom: 16px;
  }

  @include minWidth(desktop) {
    margin-top: 72px;
    margin-bottom: 90px;

    > :first-child {
      margin-right: 65px;
      margin-bottom: 0;
    }

    flex-direction: row;
  }
}

.field {
  width: 300px;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
  padding-bottom: 30px;

  font-size: 40px;

  :global(.MuiPaginationItem-root) {
    font-size: 1.2rem; // Change the font size of the buttons and numbers
    width: 2.5rem; // Change the width of the buttons and numbers
    height: 2.5rem; // Change the height of the buttons and numbers
  }
}

.noEventsFound {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
}
