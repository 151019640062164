@import "../../styles/colors.scss";
@import "../../styles/mixins/breakpoints";

.footerTop {
  background: $white;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  min-height: 158px;

  padding: 50px 0 40px 0;
}

.imageContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  img {
    max-width: 41%; //173.34px;
    max-height: 65px;
  }

  @include minWidth(desktop) {
    max-width: 1084px;
    flex-wrap: nowrap;

    padding: 0 0 0 0;

    img {
      height: auto;
      width: 100%;

      max-width: 276px;
      max-height: 103px;

      margin: 0;
    }

    img:not(:last-child) {
      margin-right: 135.99px;
    }
  }
}

.euLogo {
  margin-right: 20px;
  object-fit: contain;
}

.bamfLogo,
.minifutballLogo {
  margin: 25px 25px 0 25px;
  object-fit: contain;
}

.openFootballLogo {
  object-fit: contain;
}

.footerBottom {
  background: $purple1;
  color: $greyBlue3;
  min-height: 140px;
}

.footerBottomContainer {
  display: flex;
  flex-direction: column;

  @include minWidth(desktop) {
    max-width: 1084px;
    padding-top: 36px;
    padding-bottom: 0;
    font-size: initial;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 auto;
  }
}

.footerBottomLeft {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 40px;

  img {
    margin-bottom: 10px;
  }

  @include minWidth(desktop) {
    max-width: 420px;
    margin-bottom: 0;

    display: initial;
  }
}

.footerLogo {
  margin: 0 auto;
}

.copyright {
  color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: -25px;

  @include minWidth(desktop) {
    margin-left: 0px;
  }
}

.footerBottomRight {
  order: -1;
  margin-top: 25px;

  @include minWidth(desktop) {
    // max-width: 416px;
    order: 1;
    margin-top: 0;
  }
}

.navigation {
  color: $white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  margin: 0 auto;

  padding: 0 25px 0 25px;

  a {
    color: $white;
    margin: 10px auto;
  }

  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 10px 0;
    padding: 0 10px;

    &:not(:last-child) {
      border-right: 1px solid #fff;
    }
  }

  @include minWidth(desktop) {
    flex-direction: row;
    padding: 0;

    a {
      margin: 20px 0;
      padding: 0 20px;
      color: $white;
    }
  }
}

.footerNavDivider {
  margin: 10px auto;

  @include minWidth(desktop) {
    &:not(:last-child) {
      margin: 0 19.5px 0 0;
    }
  }
}

.socialIcons {
  margin: 38px auto 40px auto;

  display: flex;
  justify-content: center;

  @include minWidth(desktop) {
    margin: 25.27px 0 0 auto;

    justify-content: right;
    align-items: flex-end;
  }
}

.socialIcon {
  &:not(:last-child) {
    margin-right: 20px;
  }
}
