@import "../../styles/mixins/breakpoints.scss";

$backgroundTop: url("../../assets/bg-with-map-right-left-figures-top-part.png");
$backgroundBottom: url("../../assets/bg-with-map-right-left-figures-bottom-part.png");

.container {
  background: $backgroundTop center top no-repeat,
    $backgroundBottom center bottom no-repeat, #fff;

  // height: 1800px;
  height: 100%;

  @include minWidth(desktop) {
    // height: 2094px;
    min-height: 1000px;
  }
}
