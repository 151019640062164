@import "../../../styles/colors";
@import "../../../styles/mixins/eventBox.scss";
@import "../../../styles/mixins/reset";

.container {
  width: 350px;
  height: 100%;

  margin: 0 auto;

  margin-bottom: 40px;

  @include minWidth(desktop) {
    margin-left: 417px;
    flex-direction: row;
    display: flex;

    align-items: center;
    width: 1087px;
    margin-bottom: 40px;
  }
}
.date {
  display: flex;
}

.dateDay {
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dedede;

  margin-bottom: 5px;

  @include minWidth(desktop) {
    font-size: 48px;
    margin-bottom: 0;

    margin-right: 5px;
  }
}

.dateMonth {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dedede;
  margin-top: 15px;

  @include minWidth(desktop) {
    margin-top: 25px;
    font-size: 24px;
  }
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 22px;

  @include minWidth(desktop) {
    font-size: 32px;
    margin-bottom: 29px;
  }
}

.description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  // -webkit-line-clamp: 2;
  // line-clamp: 2;
  // -webkit-box-orient: vertical;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
  // -webkit-line-clamp: 5; /* Display only 5 lines */
  // max-height: calc(1em * 5); /* Adjust height based on line height */
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  margin-bottom: 15px;

  @include minWidth(desktop) {
    font-size: 18px;
    margin-bottom: 50px;
  }
}

.infoContainer {
  margin-left: 0;

  @include minWidth(desktop) {
    margin-left: 55px;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  border-radius: 15px;
  width: 100%;
  height: 220px;

  @include minWidth(desktop) {
    min-height: 340px;
    min-width: 520px;
  }
}

.textArrow {
  font-size: 12px;

  @include minWidth(desktop) {
    font-size: 18px;
  }
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: row;
  // margin: 0 auto;
  // margin-bottom: 35px;

  width: 100%;
  height: 200px;

  @include minWidth(desktop) {
    width: 520px;
    height: 320px;

    // max-width: 1096px;
    // max-height: 685px;
  }
}
