.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
  padding-bottom: 30px;

  font-size: 40px;

  :global(.MuiPaginationItem-root) {
    font-size: 1.2rem; // Change the font size of the buttons and numbers
    width: 2.5rem; // Change the width of the buttons and numbers
    height: 2.5rem; // Change the height of the buttons and numbers
  }
}
