@import "../../styles/colors";
@import "../../styles/mixins/eventBox.scss";
@import "../../styles/mixins/reset";

.contentBackgroundContainer {
  height: 100%; //1500px !important;
}

.contentContainer {
  @include minWidth(desktop) {
    padding: 64px 40px 64px 40px;

    margin-top: -250px;

    border-radius: 25px;
  }
}

.progressBar {
  height: 12px;
  width: 251px;
  border-radius: 25px;

  //   margin: 0 15px 28px 13px;

  @include minWidth(desktop) {
    // margin: 0 0 43px 23px;
    // width: 400px;
    height: 20px;

    // width: 100%;
    width: 630px;
    margin-bottom: 56px;
  }
}

.progBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 20px;
  @include minWidth(desktop) {
    margin-bottom: 0;
  }
}

.divider {
  margin-top: 79px;
  margin-bottom: 33px;
}

.saveChangesButton {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 50px;

  background-color: $purple0;

  border: 2px solid $purple0;
  color: $white;

  text-transform: uppercase;

  padding: 21px 55px 21px 55px;

  &:hover,
  &--active {
    background-color: $purple1;
    border: 2px solid $white;
    color: $white;
  }

  @include minWidth(desktop) {
    font-size: 18px;

    padding: 19px 100px 19px 100px;

    max-width: 350px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.subHeading {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 30px;

  @include minWidth(desktop) {
    margin-bottom: 40px;
  }
}

.heading {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 53px;
}

.photoContainer {
  background-color: $lightGrey4;

  border-radius: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  width: 90%;
  height: 200px;

  margin: 0 auto;

  @include minWidth(desktop) {
    width: 1096px;
    height: 685px;

    max-width: 1096px;
    max-height: 685px;
  }
}
