@import "../../styles/colors";
@import "../../styles/mixins/reset";
@import "../../styles/mixins/eventBox.scss";

.titleContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  text-wrap: wrap;

  padding-top: 50px;

  @include minWidth(desktop) {
    padding-top: 90px;
  }
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $cyan;

  margin-bottom: 17px;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}

.subTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  color: $purple1;

  margin-bottom: 30px;

  width: 80%;

  @include minWidth(desktop) {
    font-size: 49px;

    width: 932px;

    margin-bottom: 70px;
  }
}

.blogContainer {
  display: flex;

  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @include minWidth(desktop) {
    width: 1104px;
  }
}
.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;
  padding-bottom: 30px;

  font-size: 40px;

  :global(.MuiPaginationItem-root) {
    font-size: 1.2rem; // Change the font size of the buttons and numbers
    width: 2.5rem; // Change the width of the buttons and numbers
    height: 2.5rem; // Change the height of the buttons and numbers
  }
}

.blogPost {
  margin-bottom: 42px;

  @include minWidth(desktop) {
    &:nth-child(odd) {
      margin-right: 47px;
    }
  }
}
