@import "../../styles/colors";
@import "../../styles/mixins/reset";
@import "../../styles/mixins/breakpoints";

.container {
  padding-top: 20px;
  border-top: 1px solid $grey2;
  display: flex;
  justify-content: center;
  position: relative;

  > * {
    flex: 1 1 0;
  }

  @include minWidth(desktop) {
    > * {
      flex: 0 1 auto;
    }
  }
}

@mixin btn {
  @include btnReset;
  height: 35px;
  padding: 0 15px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 600;

  @include minWidth(desktop) {
    min-width: 175px;
  }
}

.submitBtn {
  // @include btn;
  // background: $darkGreyBlue5;
  // color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-radius: 50px;

  background-color: $purple0;

  border: 2px solid $purple0;
  color: $white;

  text-transform: uppercase;

  margin: 0 auto;

  margin-top: 10px;

  padding: 21px 55px 21px 55px;
  min-width: 300px;

  &:hover,
  &--active {
    background-color: $purple1;
    border: 2px solid $white;
    color: $white;
  }

  @include minWidth(desktop) {
    margin-top: 20px;
    font-size: 18px;
    padding: 19px 10px 19px 10px;
    min-width: 350px;
  }
}

.approvalBtn {
  @include btn;
  background: $blue1;
  color: #fff;
}

.awaitingApprovalBtn {
  @include btn;
  background: $grey4;
  color: $grey2;
  pointer-events: none;
}

.cancelBtn {
  @include btn;
  color: $grey4;
}

.deleteBtn {
  @include btn;
  background: transparent;
  color: red;
  position: absolute;
  right: 0;
  width: auto;
  min-width: 0;
}

.createSportEvent {
}
