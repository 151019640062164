@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

$backgroundLeft: url("./assets/heading_background_left.png");
$backgroundRight: url("./assets/assets/heading_background_right.png");

$bgk: url("./assets/header-bg.jpg");
$backgroundLeft: url("./assets/header-left.png");
$backgroundRight: url("./assets/header-right.png");
$backgroundMobile: url("./assets/header-mobile-background.png");

.container {
  height: 460px;
  background: $backgroundMobile center no-repeat, $white;

  width: 100%;

  @include minWidth(desktop) {
    height: 640px;
    background: $backgroundLeft left no-repeat, $backgroundRight right no-repeat,
      $white;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 734px;
  padding: 44px 0 0 0;

  @include minWidth(desktop) {
    padding: 175px 0 0 0;
  }
}

.title {
  color: $purple1;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 10px;

  @include minWidth(desktop) {
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 13px;
  }
}

.subtitle {
  color: $purple0;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  line-height: normal;
  text-align: center;
  margin: 0 auto;

  max-width: 294px;

  @include minWidth(desktop) {
    font-size: 18px;
    max-width: 100%;
  }
}

.callToAction {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 30px auto;

  @include minWidth(desktop) {
    flex-direction: row;
    margin: 57px auto;
  }
}

.browseSportEvents {
  display: flex;
  flex-direction: row;
}

.button {
  border-radius: 50px;
  cursor: pointer;

  color: $white;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 2px solid $cyan;
  background-color: $cyanHover;

  // padding: 19px 49.64px 14px 50.36px;
  margin-bottom: 30px;

  min-width: 250px;
  min-height: 60px;

  &:hover,
  &--active {
    background-color: transparent;
    color: $cyan;
    border-color: $cyan;
  }

  @include minWidth(desktop) {
    // padding: 20.5px 64.14px 20.5px 65.86px;
    min-width: 280px;
    min-height: 73px;

    margin-right: 28.58px;
    margin-bottom: 0;
  }
}

.browseSportEvents {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 8.92px;
}
