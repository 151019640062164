@mixin minWidth($breakpoint) {
  @if $breakpoint == tablet {
    @media (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media (min-width: 1025px) {
      @content;
    }
  }
}

@mixin maxWidth($breakpoint) {
  @if $breakpoint == smallestMobile {
    @media (max-width: 320px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media (max-width: 1024px) {
      @content;
    }
  }
}
