@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

$background: url("./bg.png");

.container {
  height: 100%;

  background: $background center bottom no-repeat, $white;
  //linear-gradient($white, rgb(244, 244, 244));

  @include minWidth(desktop) {
    height: 100%;
  }

  // margin-bottom: 300px;
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $cyan;

  // margin: 0 0 0 24px;
  margin: 0 0 24px 24px;
  padding-top: 71px;

  @include minWidth(desktop) {
    margin: 0 0 40px 417px;
    padding-top: 107px;
  }
}

.blogContainerInner {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 100px;

  & > :first-child {
    margin-bottom: 34px;
  }

  @include minWidth(desktop) {
    display: flex;
    flex-direction: initial;
    align-items: initial;

    margin-bottom: 0;

    margin-left: 420px;
    padding-bottom: 140px;

    & > :first-child {
      margin-right: 50px;
      margin-bottom: 0;
    }
  }
}
