@import "../../../styles/colors";
@import "../../../styles/mixins/eventBox.scss";
@import "../../../styles/mixins/reset";

.box {
  @include eventBox;
  width: 350px;
  height: 350px; //TODO: It was 341px in figma

  @include minWidth(desktop) {
    width: 520px;
    height: 538px;
  }

  background-color: $white;
}

// .container {
//   margin: auto;
//   margin-bottom: 21px;

//   @include minWidth(desktop) {
//     &:first-child {
//       margin: 0 0 0 417px;
//     }

//     margin: 0 0 0 47px;
//   }
// }

.date {
  margin: 0 28px 0 31px;
}

.divider {
  border-left: 2px solid #dedede;
  height: 76px;
  margin-right: 28px;

  @include minWidth(desktop) {
    height: 121px;
  }
}

.image {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  max-height: 340px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  margin: 20px 0 0 0;

  @include minWidth(desktop) {
    margin: 40px 0 25px 0;
  }
}

.dateDay {
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dedede;

  @include minWidth(desktop) {
    font-size: 48px;
  }
}

.dateMonth {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #dedede;

  @include minWidth(desktop) {
    font-size: 20px;
  }
}

.title {
  font-size: 14px; //TODO: It was 16 in figma
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  word-wrap: wrap;

  margin-bottom: 12px;

  @include minWidth(desktop) {
    font-size: 24px;
    margin-bottom: 29px;
  }
}

.photoContainer {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  width: 100%;
  height: 230px;

  @include minWidth(desktop) {
    width: 100%;
    height: 320px;
  }
}
