@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

$backgroundDots: url("./assets/dots-bg.png");
$hands: url("./assets/hands-bg.png");

.container {
  background: $backgroundDots top no-repeat, $hands bottom right no-repeat,
    $purple0;

  background-size: 600px 600px, 933px 200px;

  @include minWidth(desktop) {
    background: $backgroundDots bottom left no-repeat,
      $hands bottom right no-repeat, $purple0;

    background-size: 933px 924px, 933px 200px;
  }
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 193px;

  @include minWidth(desktop) {
    flex-direction: row;

    padding-bottom: 200px;

    :first-child {
      margin-right: 36.3px;
    }
  }
}

.heading {
  color: $white;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 73px;

  margin-bottom: 50px;
}
