@import "../../../styles/colors.scss";
@import "../../../styles/mixins/breakpoints.scss";

$mapBackground: url("./assets/map-bg.jpg");

.container {
  height: auto;
  background: $mapBackground top no-repeat, $white;
  //linear-gradient($white, rgb(244, 244, 244));
}

.headerContainer {
  padding: 72px 23px 30px 23px;

  @include minWidth(desktop) {
    padding: 103px 416px 68px 414px;
  }
}

.navigatinoSubtitleContainer {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $cyan;
  text-transform: uppercase;

  @include minWidth(desktop) {
    font-size: 24px;
  }
}

.subtitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: $purple1;

  @include minWidth(desktop) {
    font-size: 49px;

    max-width: 835px;
  }
}

.textArrowContainer {
  margin: 0 0 0 23px;
  // padding-bottom: 50px;

  @include minWidth(desktop) {
    margin: 0 0 0 414px;
    // padding-bottom: 103px;
  }
}

.pushDown {
  padding-bottom: 50px;
}
